export const PRESHIPMENT_CREATE_REQUEST = 'PRESHIPMENT_CREATE_REQUEST'
export const PRESHIPMENT_CREATE_SUCCESS = 'PRESHIPMENT_CREATE_SUCCESS'
export const PRESHIPMENT_CREATE_FAIL = 'PRESHIPMENT_CREATE_FAIL'
export const PRESHIPMENT_CREATE_RESET = 'PRESHIPMENT_CREATE_RESET'

export const PRESHIPMENT_LIST_REQUEST = 'PRESHIPMENT_LIST_REQUEST'
export const PRESHIPMENT_LIST_SUCCESS = 'PRESHIPMENT_LIST_SUCCESS'
export const PRESHIPMENT_LIST_FAIL = 'PRESHIPMENT_LIST_FAIL'
export const PRESHIPMENT_LIST_RESET = 'PRESHIPMENT_LIST_RESET'

export const PRESHIPMENT_CANCEL_REQUEST = 'PRESHIPMENT_CANCEL_REQUEST'
export const PRESHIPMENT_CANCEL_SUCCESS = 'PRESHIPMENT_CANCEL_SUCCESS'
export const PRESHIPMENT_CANCEL_FAIL = 'PRESHIPMENT_CANCEL_FAIL'
export const PRESHIPMENT_CANCEL_RESET = 'PRESHIPMENT_CANCEL_RESET'

export const PRESHIPMENT_BYID_REQUEST = 'PRESHIPMENT_BYID_REQUEST'
export const PRESHIPMENT_BYID_SUCCESS = 'PRESHIPMENT_BYID_SUCCESS'
export const PRESHIPMENT_BYID_FAIL = 'PRESHIPMENT_BYID_FAIL'
export const PRESHIPMENT_BYID_RESET = 'PRESHIPMENT_BYID_RESET'

export const PRESHIPMENT_ADD_REMOVE_ITEM_REQUEST = 'PRESHIPMENT_ADD_REMOVE_ITEM_REQUEST'
export const PRESHIPMENT_ADD_REMOVE_ITEM_SUCCESS = 'PRESHIPMENT_ADD_REMOVE_ITEM_SUCCESS'
export const PRESHIPMENT_ADD_REMOVE_ITEM_FAIL = 'PRESHIPMENT_ADD_REMOVE_ITEM_FAIL'
export const PRESHIPMENT_ADD_REMOVE_ITEM_RESET = 'PRESHIPMENT_ADD_REMOVE_ITEM_RESET'

export const PRESHIPMENT_SAVE_DHL_SHIPPER_REQUEST = 'PRESHIPMENT_SAVE_DHL_SHIPPER_REQUEST'
export const PRESHIPMENT_SAVE_DHL_SHIPPER_SUCCESS = 'PRESHIPMENT_SAVE_DHL_SHIPPER_SUCCESS'
export const PRESHIPMENT_SAVE_DHL_SHIPPER_FAIL = 'PRESHIPMENT_SAVE_DHL_SHIPPER_FAIL'
export const PRESHIPMENT_SAVE_DHL_SHIPPER_RESET = 'PRESHIPMENT_SAVE_DHL_SHIPPER_RESET'

export const PRESHIPMENT_ITEM_QTY_REQUEST = 'PRESHIPMENT_ITEM_QTY_REQUEST'
export const PRESHIPMENT_ITEM_QTY_SUCCESS = 'PRESHIPMENT_ITEM_QTY_SUCCESS'
export const PRESHIPMENT_ITEM_QTY_FAIL = 'PRESHIPMENT_ITEM_QTY_FAIL'
export const PRESHIPMENT_ITEM_QTY_RESET = 'PRESHIPMENT_ITEM_QTY_RESET'

export const PRESHIPMENT_BOXSIZE_REQUEST = 'PRESHIPMENT_BOXSIZE_REQUEST'
export const PRESHIPMENT_BOXSIZE_SUCCESS = 'PRESHIPMENT_BOXSIZE_SUCCESS'
export const PRESHIPMENT_BOXSIZE_FAIL = 'PRESHIPMENT_BOXSIZE_FAIL'
export const PRESHIPMENT_BOXSIZE_RESET = 'PRESHIPMENT_BOXSIZE_RESET'

export const PRESHIPMENT_BOXEDIT_REQUEST = 'PRESHIPMENT_BOXEDIT_REQUEST'
export const PRESHIPMENT_BOXEDIT_SUCCESS = 'PRESHIPMENT_BOXEDIT_SUCCESS'
export const PRESHIPMENT_BOXEDIT_FAIL = 'PRESHIPMENT_BOXEDIT_FAIL'
export const PRESHIPMENT_BOXEDIT_RESET = 'PRESHIPMENT_BOXEDIT_RESET'

export const PRESHIPMENT_SAVECONTACT_REQUEST = 'PRESHIPMENT_SAVECONTACT_REQUEST'
export const PRESHIPMENT_SAVECONTACT_SUCCESS = 'PRESHIPMENT_SAVECONTACT_SUCCESS'
export const PRESHIPMENT_SAVECONTACT_FAIL = 'PRESHIPMENT_SAVECONTACT_FAIL'
export const PRESHIPMENT_SAVECONTACT_RESET = 'PRESHIPMENT_SAVECONTACT_RESET'

export const PRESHIPMENT_SAVESHIPPER_REQUEST = 'PRESHIPMENT_SAVESHIPPER_REQUEST'
export const PRESHIPMENT_SAVESHIPPER_SUCCESS = 'PRESHIPMENT_SAVESHIPPER_SUCCESS'
export const PRESHIPMENT_SAVESHIPPER_FAIL = 'PRESHIPMENT_SAVESHIPPER_FAIL'
export const PRESHIPMENT_SAVESHIPPER_RESET = 'PRESHIPMENT_SAVESHIPPER_RESET'

export const PRESHIPMENT_AGENT_RECEIVER_UPDATE_REQUEST = 'PRESHIPMENT_AGENT_RECEIVER_UPDATE_REQUEST'
export const PRESHIPMENT_AGENT_RECEIVER_UPDATE_SUCCESS = 'PRESHIPMENT_AGENT_RECEIVER_UPDATE_SUCCESS'
export const PRESHIPMENT_AGENT_RECEIVER_UPDATE_FAIL = 'PRESHIPMENT_AGENT_RECEIVER_UPDATE_FAIL'
export const PRESHIPMENT_AGENT_RECEIVER_UPDATE_RESET = 'PRESHIPMENT_AGENT_RECEIVER_UPDATE_RESET'


export const PRESHIPMENT_NOTE_UPDATE_REQUEST = 'PRESHIPMENT_NOTE_UPDATE_REQUEST'
export const PRESHIPMENT_NOTE_UPDATE_SUCCESS = 'PRESHIPMENT_NOTE_UPDATE_SUCCESS'
export const PRESHIPMENT_NOTE_UPDATE_FAIL = 'PRESHIPMENT_NOTE_UPDATE_FAIL'
export const PRESHIPMENT_NOTE_UPDATE_RESET = 'PRESHIPMENT_NOTE_UPDATE_RESET'

export const PRESHIPMENT_CURRENCY_UPDATE_REQUEST = 'PRESHIPMENT_CURRENCY_UPDATE_REQUEST'
export const PRESHIPMENT_CURRENCY_UPDATE_SUCCESS = 'PRESHIPMENT_CURRENCY_UPDATE_SUCCESS'
export const PRESHIPMENT_CURRENCY_UPDATE_FAIL = 'PRESHIPMENT_CURRENCY_UPDATE_FAIL'
export const PRESHIPMENT_CURRENCY_UPDATE_RESET = 'PRESHIPMENT_CURRENCY_UPDATE_RESET'

export const PRESHIPMENT_ITEM_SORTING_REQUEST = 'PRESHIPMENT_ITEM_SORTING_REQUEST'
export const PRESHIPMENT_ITEM_SORTING_SUCCESS = 'PRESHIPMENT_ITEM_SORTING_SUCCESS'
export const PRESHIPMENT_ITEM_SORTING_FAIL = 'PRESHIPMENT_ITEM_SORTING_FAIL'
export const PRESHIPMENT_ITEM_SORTING_RESET = 'PRESHIPMENT_ITEM_SORTING_RESET'

export const PRESHIPMENT_VPLUS_REQUEST = 'PRESHIPMENT_VPLUS_REQUEST'
export const PRESHIPMENT_VPLUS_SUCCESS = 'PRESHIPMENT_VPLUS_SUCCESS'
export const PRESHIPMENT_VPLUS_FAIL = 'PRESHIPMENT_VPLUS_FAIL'
export const PRESHIPMENT_VPLUS_RESET = 'PRESHIPMENT_VPLUS_RESET'

export const PRESHIPMENT_SPECIALROUTE_REQUEST = 'PRESHIPMENT_SPECIALROUTE_REQUEST'
export const PRESHIPMENT_SPECIALROUTE_SUCCESS = 'PRESHIPMENT_SPECIALROUTE_SUCCESS'
export const PRESHIPMENT_SPECIALROUTE_FAIL = 'PRESHIPMENT_SPECIALROUTE_FAIL'
export const PRESHIPMENT_SPECIALROUTE_RESET = 'PRESHIPMENT_SPECIALROUTE_RESET'

export const PRESHIPMENT_SHIPTYPE_REQUEST = 'PRESHIPMENT_SHIPTYPE_REQUEST'
export const PRESHIPMENT_SHIPTYPE_SUCCESS = 'PRESHIPMENT_SHIPTYPE_SUCCESS'
export const PRESHIPMENT_SHIPTYPE_FAIL = 'PRESHIPMENT_SHIPTYPE_FAIL'
export const PRESHIPMENT_SHIPTYPE_RESET = 'PRESHIPMENT_SHIPTYPE_RESET'

export const SHIPMENT_CREATE_REQUEST = 'SHIPMENT_CREATE_REQUEST'
export const SHIPMENT_CREATE_SUCCESS = 'SHIPMENT_CREATE_SUCCESS'
export const SHIPMENT_CREATE_FAIL = 'SHIPMENT_CREATE_FAIL'
export const SHIPMENT_CREATE_RESET = 'SHIPMENT_CREATE_RESET'

export const SHIPMENT_GET_COST_REQUEST = 'SHIPMENT_GET_COST_REQUEST'
export const SHIPMENT_GET_COST_SUCCESS = 'SHIPMENT_GET_COST_SUCCESS'
export const SHIPMENT_GET_COST_FAIL = 'SHIPMENT_GET_COST_FAIL'
export const SHIPMENT_GET_COST_RESET = 'SHIPMENT_GET_COST_RESET'

export const SHIPMENT_LIST_REQUEST = 'SHIPMENT_LIST_REQUEST'
export const SHIPMENT_LIST_SUCCESS = 'SHIPMENT_LIST_SUCCESS'
export const SHIPMENT_LIST_FAIL = 'SHIPMENT_LIST_FAIL'
export const SHIPMENT_LIST_RESET = 'SHIPMENT_LIST_RESET'

export const SHIPMENT_DELETED_LIST_REQUEST = 'SHIPMENT_DELETED_LIST_REQUEST'
export const SHIPMENT_DELETED_LIST_SUCCESS = 'SHIPMENT_DELETED_LIST_SUCCESS'
export const SHIPMENT_DELETED_LIST_FAIL = 'SHIPMENT_DELETED_LIST_FAIL'
export const SHIPMENT_DELETED_LIST_RESET = 'SHIPMENT_DELETED_LIST_RESET'

export const SHIPMENT_MY_REQUEST = 'SHIPMENT_MY_REQUEST'
export const SHIPMENT_MY_SUCCESS = 'SHIPMENT_MY_SUCCESS'
export const SHIPMENT_MY_FAIL = 'SHIPMENT_MY_FAIL'
export const SHIPMENT_MY_RESET = 'SHIPMENT_MY_RESET'

export const SHIPMENT_FILTER_REQUEST = 'SHIPMENT_FILTER_REQUEST'
export const SHIPMENT_FILTER_SUCCESS = 'SHIPMENT_FILTER_SUCCESS'
export const SHIPMENT_FILTER_FAIL = 'SHIPMENT_FILTER_FAIL'
export const SHIPMENT_FILTER_RESET = 'SHIPMENT_FILTER_RESET'

export const SHIPMENT_BYID_REQUEST = 'SHIPMENT_BYID_REQUEST'
export const SHIPMENT_BYID_SUCCESS = 'SHIPMENT_BYID_SUCCESS'
export const SHIPMENT_BYID_FAIL = 'SHIPMENT_BYID_FAIL'
export const SHIPMENT_BYID_RESET = 'SHIPMENT_BYID_RESET'

export const SHIPMENT_FORINVOICE_REQUEST = 'SHIPMENT_FORINVOICE_REQUEST'
export const SHIPMENT_FORINVOICE_SUCCESS = 'SHIPMENT_FORINVOICE_SUCCESS'
export const SHIPMENT_FORINVOICE_FAIL = 'SHIPMENT_FORINVOICE_FAIL'
export const SHIPMENT_FORINVOICE_RESET = 'SHIPMENT_FORINVOICE_RESET'

export const SHIPMENT_CANCEL_REQUEST = 'SHIPMENT_CANCEL_REQUEST'
export const SHIPMENT_CANCEL_SUCCESS = 'SHIPMENT_CANCEL_SUCCESS'
export const SHIPMENT_CANCEL_FAIL = 'SHIPMENT_CANCEL_FAIL'
export const SHIPMENT_CANCEL_RESET = 'SHIPMENT_CANCEL_RESET'

export const SHIPMENT_ADDNOTE_REQUEST = 'SHIPMENT_ADDNOTE_REQUEST'
export const SHIPMENT_ADDNOTE_SUCCESS = 'SHIPMENT_ADDNOTE_SUCCESS'
export const SHIPMENT_ADDNOTE_FAIL = 'SHIPMENT_ADDNOTE_FAIL'
export const SHIPMENT_ADDNOTE_RESET = 'SHIPMENT_ADDNOTE_RESET'

export const SHIPMENT_ADDCHARGE_REQUEST = 'SHIPMENT_ADDCHARGE_REQUEST'
export const SHIPMENT_ADDCHARGE_SUCCESS = 'SHIPMENT_ADDCHARGE_SUCCESS'
export const SHIPMENT_ADDCHARGE_FAIL = 'SHIPMENT_ADDCHARGE_FAIL'
export const SHIPMENT_ADDCHARGE_RESET = 'SHIPMENT_ADDCHARGE_RESET'

export const SHIPMENT_DELETENOTE_REQUEST = 'SHIPMENT_DELETENOTE_REQUEST'
export const SHIPMENT_DELETENOTE_SUCCESS = 'SHIPMENT_DELETENOTE_SUCCESS'
export const SHIPMENT_DELETENOTE_FAIL = 'SHIPMENT_DELETENOTE_FAIL'
export const SHIPMENT_DELETENOTE_RESET = 'SHIPMENT_DELETENOTE_RESET'

export const SHIPMENT_GETCONTACTFOR_PRESHIP_REQUEST = 'SHIPMENT_GETCONTACTFOR_PRESHIP_REQUEST'
export const SHIPMENT_GETCONTACTFOR_PRESHIP_SUCCESS = 'SHIPMENT_GETCONTACTFOR_PRESHIP_SUCCESS'
export const SHIPMENT_GETCONTACTFOR_PRESHIP_FAIL = 'SHIPMENT_GETCONTACTFOR_PRESHIP_FAIL'
export const SHIPMENT_GETCONTACTFOR_PRESHIP_RESET = 'SHIPMENT_GETCONTACTFOR_PRESHIP_RESET'

export const SHIPMENT_TRACKING_REQUEST = 'SHIPMENT_TRACKING_REQUEST'
export const SHIPMENT_TRACKING_SUCCESS = 'SHIPMENT_TRACKING_SUCCESS'
export const SHIPMENT_TRACKING_FAIL = 'SHIPMENT_TRACKING_FAIL'
export const SHIPMENT_TRACKING_RESET = 'SHIPMENT_TRACKING_RESET'

export const SHIPMENT_RETURNED_REQUEST = 'SHIPMENT_RETURNED_REQUEST'
export const SHIPMENT_RETURNED_SUCCESS = 'SHIPMENT_RETURNED_SUCCESS'
export const SHIPMENT_RETURNED_FAIL = 'SHIPMENT_RETURNED_FAIL'
export const SHIPMENT_RETURNED_RESET = 'SHIPMENT_RETURNED_RESET'

export const SHIPMENT_SCANNED_REQUEST = 'SHIPMENT_SCANNED_REQUEST'
export const SHIPMENT_SCANNED_SUCCESS = 'SHIPMENT_SCANNED_SUCCESS'
export const SHIPMENT_SCANNED_FAIL = 'SHIPMENT_SCANNED_FAIL'
export const SHIPMENT_SCANNED_RESET = 'SHIPMENT_SCANNED_RESET'

export const SHIPMENT_SALEBILLING_REQUEST = 'SHIPMENT_SALEBILLING_REQUEST'
export const SHIPMENT_SALEBILLING_SUCCESS = 'SHIPMENT_SALEBILLING_SUCCESS'
export const SHIPMENT_SALEBILLING_FAIL = 'SHIPMENT_SALEBILLING_FAIL'
export const SHIPMENT_SALEBILLING_RESET = 'SHIPMENT_SALEBILLING_RESET'

export const SHIPMENT_AGENTBILLING_REQUEST = 'SHIPMENT_AGENTBILLING_REQUEST'
export const SHIPMENT_AGENTBILLING_SUCCESS = 'SHIPMENT_AGENTBILLING_SUCCESS'
export const SHIPMENT_AGENTBILLING_FAIL = 'SHIPMENT_AGENTBILLING_FAIL'
export const SHIPMENT_AGENTBILLING_RESET = 'SHIPMENT_AGENTBILLING_RESET'

export const SHIPMENT_MYAGENTBILLING_REQUEST = 'SHIPMENT_MYAGENTBILLING_REQUEST'
export const SHIPMENT_MYAGENTBILLING_SUCCESS = 'SHIPMENT_MYAGENTBILLING_SUCCESS'
export const SHIPMENT_MYAGENTBILLING_FAIL = 'SHIPMENT_MYAGENTBILLING_FAIL'
export const SHIPMENT_MYAGENTBILLING_RESET = 'SHIPMENT_MYAGENTBILLING_RESET'

export const SHIPMENT_USERSHIPCOUNT_REQUEST = 'SHIPMENT_USERSHIPCOUNT_REQUEST'
export const SHIPMENT_USERSHIPCOUNT_SUCCESS = 'SHIPMENT_USERSHIPCOUNT_SUCCESS'
export const SHIPMENT_USERSHIPCOUNT_FAIL = 'SHIPMENT_USERSHIPCOUNT_FAIL'
export const SHIPMENT_USERSHIPCOUNT_RESET = 'SHIPMENT_USERSHIPCOUNT_RESET'

export const SHIPMENT_GET_SCANNED_REQUEST = 'SHIPMENT_GET_SCANNED_REQUEST'
export const SHIPMENT_GET_SCANNED_SUCCESS = 'SHIPMENT_GET_SCANNED_SUCCESS'
export const SHIPMENT_GET_SCANNED_FAIL = 'SHIPMENT_GET_SCANNED_FAIL'
export const SHIPMENT_GET_SCANNED_RESET = 'SHIPMENT_GET_SCANNED_RESET'

export const SHIPMENT_SHIPANDSCAN_REQUEST = 'SHIPMENT_SHIPANDSCAN_REQUEST'
export const SHIPMENT_SHIPANDSCAN_SUCCESS = 'SHIPMENT_SHIPANDSCAN_SUCCESS'
export const SHIPMENT_SHIPANDSCAN_FAIL = 'SHIPMENT_SHIPANDSCAN_FAIL'
export const SHIPMENT_SHIPANDSCAN_RESET = 'SHIPMENT_SHIPANDSCAN_RESET'

export const SHIPMENT_DAILYREPORT_REQUEST = 'SHIPMENT_DAILYREPORT_REQUEST'
export const SHIPMENT_DAILYREPORT_SUCCESS = 'SHIPMENT_DAILYREPORT_SUCCESS'
export const SHIPMENT_DAILYREPORT_FAIL = 'SHIPMENT_DAILYREPORT_FAIL'
export const SHIPMENT_DAILYREPORT_RESET = 'SHIPMENT_DAILYREPORT_RESET'

export const SHIPMENT_CHANGEAGENT_REQUEST = 'SHIPMENT_CHANGEAGENT_REQUEST'
export const SHIPMENT_CHANGEAGENT_SUCCESS = 'SHIPMENT_CHANGEAGENT_SUCCESS'
export const SHIPMENT_CHANGEAGENT_FAIL = 'SHIPMENT_CHANGEAGENT_FAIL'
export const SHIPMENT_CHANGEAGENT_RESET = 'SHIPMENT_CHANGEAGENT_RESET'

export const SHIPMENT_STATUS_REQUEST = 'SHIPMENT_STATUS_REQUEST'
export const SHIPMENT_STATUS_SUCCESS = 'SHIPMENT_STATUS_SUCCESS'
export const SHIPMENT_STATUS_FAIL = 'SHIPMENT_STATUS_FAIL'
export const SHIPMENT_STATUS_RESET = 'SHIPMENT_STATUS_RESET'

export const SHIPMENT_STATUS_SALE_REQUEST = 'SHIPMENT_STATUS_SALE_REQUEST'
export const SHIPMENT_STATUS_SALE_SUCCESS = 'SHIPMENT_STATUS_SALE_SUCCESS'
export const SHIPMENT_STATUS_SALE_FAIL = 'SHIPMENT_STATUS_SALE_FAIL'
export const SHIPMENT_STATUS_SALE_RESET = 'SHIPMENT_STATUS_SALE_RESET'

export const SHIPMENT_STATUS_EACH_REQUEST = 'SHIPMENT_STATUS_EACH_REQUEST'
export const SHIPMENT_STATUS_EACH_SUCCESS = 'SHIPMENT_STATUS_EACH_SUCCESS'
export const SHIPMENT_STATUS_EACH_FAIL = 'SHIPMENT_STATUS_EACH_FAIL'
export const SHIPMENT_STATUS_EACH_RESET = 'SHIPMENT_STATUS_EACH_RESET'

export const SHIPMENT_STATUS_MY_REQUEST = 'SHIPMENT_STATUS_MY_REQUEST'
export const SHIPMENT_STATUS_MY_SUCCESS = 'SHIPMENT_STATUS_MY_SUCCESS'
export const SHIPMENT_STATUS_MY_FAIL = 'SHIPMENT_STATUS_MY_FAIL'
export const SHIPMENT_STATUS_MY_RESET = 'SHIPMENT_STATUS_MY_RESET'

export const SHIPMENT_UPDATE_COST_REQUEST = 'SHIPMENT_UPDATE_COST_REQUEST'
export const SHIPMENT_UPDATE_COST_SUCCESS = 'SHIPMENT_UPDATE_COST_SUCCESS'
export const SHIPMENT_UPDATE_COST_FAIL = 'SHIPMENT_UPDATE_COST_FAIL'
export const SHIPMENT_UPDATE_COST_RESET = 'SHIPMENT_UPDATE_COST_RESET'

export const SHIPMENT_UPDATE_AWB_REQUEST = 'SHIPMENT_UPDATE_AWB_REQUEST'
export const SHIPMENT_UPDATE_AWB_SUCCESS = 'SHIPMENT_UPDATE_AWB_SUCCESS'
export const SHIPMENT_UPDATE_AWB_FAIL = 'SHIPMENT_UPDATE_AWB_FAIL'
export const SHIPMENT_UPDATE_AWB_RESET = 'SHIPMENT_UPDATE_AWB_RESET'

export const SHIPMENT_PULL_DATA_REQUEST = 'SHIPMENT_PULL_DATA_REQUEST'
export const SHIPMENT_PULL_DATA_SUCCESS = 'SHIPMENT_PULL_DATA_SUCCESS'
export const SHIPMENT_PULL_DATA_FAIL = 'SHIPMENT_PULL_DATA_FAIL'
export const SHIPMENT_PULL_DATA_RESET = 'SHIPMENT_PULL_DATA_RESET'

export const SHIPMENT_VPLUS_REQUEST = 'SHIPMENT_VPLUS_REQUEST'
export const SHIPMENT_VPLUS_SUCCESS = 'SHIPMENT_VPLUS_SUCCESS'
export const SHIPMENT_VPLUS_FAIL = 'SHIPMENT_VPLUS_FAIL'
export const SHIPMENT_VPLUS_RESET = 'SHIPMENT_VPLUS_RESET'

export const SHIPMENT_DHLACCOUNT_REQUEST = 'SHIPMENT_DHLACCOUNT_REQUEST'
export const SHIPMENT_DHLACCOUNT_SUCCESS = 'SHIPMENT_DHLACCOUNT_SUCCESS'
export const SHIPMENT_DHLACCOUNT_FAIL = 'SHIPMENT_DHLACCOUNT_FAIL'
export const SHIPMENT_DHLACCOUNT_RESET = 'SHIPMENT_DHLACCOUNT_RESET'


