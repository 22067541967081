import React,{useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    Box, Button, Divider, HStack, Input, Select, Spinner, Stack, Table, Tbody, Td, Th, Thead, Tr, useToast

} from '@chakra-ui/react'
import Error from '../components/Error'
import { namesRate, getByIdRate, createRate } from '../actions/rateActions'
import { namesAgentRate, getByIdAgentRate } from '../actions/agentRateActions'
import { AGENTRATE_BYID_RESET } from '../constants/agentRateConstants'
import { RATE_BYID_RESET, RATE_CREATE_RESET } from '../constants/rateConstants'
import { useNavigate } from 'react-router-dom'

const RateCreateScreen = () => {
    const dispatch = useDispatch()
    const toast = useToast()
    const navigate = useNavigate()
    const {zone} = useSelector(state=>state.zone.Rate)
    const [fuel,setFuel] = useState(0)
    const [ess, setEss] = useState(zone.essList)
    const [vat, setVat] = useState(0)
    const [name, setName] = useState('')
    const [effDate, setEffDate] = useState('')
    const [rate, setRate] = useState([])
    const [goGreen, setGoGreen] = useState(0)
    // const zoneNames = ['KG', 'zone1','zone1.1','zone2', 'zone3','zone3.1','zone3.2', 'zone4','zone4.1', 'zone5','zone5.1', 'zone6','zone6.1', 'zone7','zone7.1','zone7.2', 'zone8','zone8.1','zone8.2', 'zone9','zone9.1']
    // const kglistOld = [0.5,1.0,1.5,2.0,0.5,1.0,2.0,3.0,4.0,5.0,6.0,7.0,8.0,9.0,10.00,11.0,12.0,13.0,14.0,15.0,16.0,17.0,18.0,19.0,20.0,21.0,22.0,23.0,24.0,25.0,26.0,27.0,28.0,29.0,30.0,30]
    // const kgNameOld = ['Doc ', 'Doc ', 'Doc ', 'Doc ', 'Pkg ', 'Pkg ', 'Pkg ', 'Pkg ', 'Pkg ', 'Pkg ', 'Pkg ', 'Pkg ', 'Pkg ', 'Pkg ', 'Pkg ', 'Pkg ', 'Pkg ', 'Pkg ', 'Pkg ', 'Pkg ', 'Pkg ', 'Pkg ', 'Pkg ', 'Pkg ', 'Pkg ', 'Pkg ', 'Pkg ', 'Pkg ', 'Pkg ', 'Pkg ', 'Pkg ', 'Pkg ', 'Pkg ', 'Pkg ', 'Pkg ', 'Ovr ']
    // const kgSavingListOld = ['d5','d10','d15','d20','p5','p10','p20','p30','p40','p50','p60','p70','p80','p90','p100','p110','p120','p130','p140','p150','p160','p170','p180','p190','p200','p210','p220','p230','p240','p250','p260','p270','p280','p290','p300','o300']
    
    
    const kglist = [
        0.5,1.0,1.5,2.0,
        0.5,1.0,1.5,2.0,2.5,3.0,3.5,4.0,4.5,5.0,5.5,6.0,6.5,7.0,7.5,8.0,8.5,9.0,9.5,10.0,10.5,
        11.0,11.5,12.0,12.5,13.0,13.5,14.0,14.5,15.0,15.5,16.0,16.5,17.0,17.5,18.0,18.5,19.0,19.5,20.0,20.5,
        21.0,21.5,22.0,22.5,23.0,23.5,24.0,24.5,25.0,25.5,26.0,26.5,27.0,27.5,28.0,28.5,29.0,29.5,30.0,30
    ]
    const kgName = [
        'Doc ', 'Doc ', 'Doc ', 'Doc ', 
        'Pkg ', 'Pkg ', 'Pkg ', 'Pkg ', 'Pkg ', 'Pkg ', 'Pkg ', 'Pkg ', 'Pkg ', 'Pkg ', 
        'Pkg ', 'Pkg ', 'Pkg ', 'Pkg ', 'Pkg ', 'Pkg ', 'Pkg ', 'Pkg ', 'Pkg ', 'Pkg ', 
        'Pkg ', 'Pkg ', 'Pkg ', 'Pkg ', 'Pkg ', 'Pkg ', 'Pkg ', 'Pkg ', 'Pkg ', 'Pkg ', 
        'Pkg ', 'Pkg ', 'Pkg ', 'Pkg ', 'Pkg ', 'Pkg ', 'Pkg ', 'Pkg ', 'Pkg ', 'Pkg ', 
        'Pkg ', 'Pkg ', 'Pkg ', 'Pkg ', 'Pkg ', 'Pkg ', 'Pkg ', 'Pkg ', 'Pkg ', 'Pkg ', 
        'Pkg ', 'Pkg ', 'Pkg ', 'Pkg ', 'Pkg ', 'Pkg ', 'Pkg ', 'Pkg ', 'Pkg ', 'Pkg ', 
        'Ovr '] 
    const kgSavingList = 
    [
        'd5','d10','d15','d20',
        'p5','p10', 'p15', 'p20', 'p25','p30', 'p35', 'p40', 'p45', 'p50', 'p55', 'p60', 'p65', 'p70', 'p75', 'p80', 'p85', 'p90', 'p95', 'p100', 'p105',
        'p110', 'p115', 'p120', 'p125', 'p130', 'p135', 'p140', 'p145', 'p150', 'p155', 'p160', 'p165', 'p170', 'p175', 'p180', 'p185', 'p190', 'p195', 'p200', 'p205', 
        'p210', 'p215', 'p220', 'p225', 'p230', 'p235', 'p240', 'p245', 'p250', 'p255', 'p260', 'p265', 'p270', 'p275', 'p280', 'p285', 'p290', 'p295', 'p300','o300'
    ]
    
    const {loading: loadingRateById, error: errorRateByid, rateDetail} = useSelector(state=>state.rate.ById)
    const {loading: loadingRateName, error: errorRateName, ratenames} = useSelector(state=>state.rate.Names)
    const {loading: loadingAgentRateName, error: errorAgentRateName, agentratenames} = useSelector(state=>state.agentRate.Names)
    const {loading: loadingAgentRate, error: errorAgentRate, agentrate} = useSelector(state=>state.agentRate.ById)
    const {loading, error, success} = useSelector(state=>state.rate.Create)

    const agentRateIdx = [
        "doc",
        "doc",
        "doc",
        "doc",
        "p0",
        "p0",
        "p0",
        "p0",
        "p0",
        "p0",
        "p0",
        "p0",
        "p0",
        "p0",
        "p5",
        "p5",
        "p5",
        "p5",
        "p5",
        "p5",
        "p5",
        "p5",
        "p5",
        "p5",
        "p10",
        "p10",
        "p10",
        "p10",
        "p10",
        "p10",
        "p10",
        "p10",
        "p10",
        "p10",
        "p15",
        "p15",
        "p15",
        "p15",
        "p15",
        "p15",
        "p15",
        "p15",
        "p15",
        "p15",
        "p20",
        "p20",
        "p20",
        "p20",
        "p20",
        "p20",
        "p20",
        "p20",
        "p20",
        "p20",
        "p25",
        "p25",
        "p25",
        "p25",
        "p25",
        "p25",
        "p25",
        "p25",
        "p25",
        "p25",
        "p30",
        "p70",
        "p300",
    ]

    useEffect(()=>{
        if(zone.zArray.length === 0){
            navigate('/rate')
        } else {
            setRate([...zone.newRateFormat])
        }
    },[zone, navigate])

    // useEffect(()=>{
    //     if(successDetail){
    //         rate.forEach((r,idx)=>{
    //             zone.zArray.forEach((za,i)=>{
    //                 rate[idx][za] = computeRate(rateDetail.ratelist[idx].rate[za], kglist[idx], ess[i], fuel, vat, idx ) + getAgentRateIndex(idx, za)
    //             })
    //         })
    //     }
    // },[successDetail])

    useEffect(()=>{
        dispatch(namesRate())
        dispatch(namesAgentRate())
        if(success){
            setName('')
            setEffDate('')
            setEss([])
            setFuel(0)
            setVat(0)
            toast({
                title: 'Rate created successfully!',
                status: 'success',
                position:'top',
                duration: 2000,
                isClosable: true,
              })
        }
        return()=>{
            dispatch({type: AGENTRATE_BYID_RESET})
            dispatch({type: RATE_BYID_RESET})
            dispatch({type: RATE_CREATE_RESET})
        }
    },[dispatch, success, toast])

    const handleGetRateById = (rateId) => {
        dispatch(getByIdRate(rateId))
    }
    const handleGetAgentRateById = (agRateId) => {
        dispatch(getByIdAgentRate(agRateId))
    }

    function getAgentRateIndex(i, z){
        let name
        // if(i <= 3){name='doc'}
        // // else if(i > 3 && i <= 9){name='p0'}
        // // else if(i > 9 && i <= 14){name='p5'}
        // // else if(i > 14 && i <= 19){name='p10'}
        // // else if(i > 19 && i <= 24){name='p15'}
        // // else if(i > 24 && i <= 29){name='p20'}
        // // else if(i > 29 && i <= 34){name='p25'}

        // else if(i > 3 && i <= 13){name='p0'}
        // else if(i > 14 && i <= 23){name='p5'}
        // else if(i > 23 && i <= 33){name='p10'}
        // else if(i > 33 && i <= 43){name='p15'}
        // else if(i > 43 && i <= 53){name='p20'}
        // else if(i > 53 && i <= 63){name='p25'}

        // // one one 35
        // // new point fivne 64
        // else if(i === 64){name='p30'}
        // else {name='p70'}
        name = agentRateIdx[i]
        const rNum = agentrate ? Number(agentrate[name][z]) : ''
        return rNum
    }


    const computeRate = (n, k, e, f, v, i) => {
        //console.log(n, k, e, f, v, i)
        //net, kg, ess, fuel, vat, index of rate
        var finalNum = 0
        if(n === undefined){
            return finalNum
        }
        //one one length 36, index 35
        //new point five length 65, ondex 64
        if(i===64){         
            const fuelValue = (n + e) * (f/100)
            const saleValue = n + e + fuelValue
            finalNum = Number(saleValue + (saleValue*(v/100))) + Number(goGreen)
            //((n + e + ((n + e) * (f/100))) + ((n + e + ((n + e) * (f/100)*(v/100)))
            //console.log(finalNum, goGreen, finalNum+ goGreen)
        } else {
            const essValue = e * k
            const fuelValue = (n+essValue)*(f/100)
            const saleValue = essValue + fuelValue + n
            const computedGoGreen = Math.ceil(k*goGreen)
            finalNum = saleValue + (saleValue*(v/100)) + computedGoGreen
            // ((e * k) + ( (n+(e*k))*(f/100) ) + n) + ( ((e * k) + ( (n+(e*k))*(f/100) ) + n) * (v/100) )
        }
        //console.log(finalNum)
        let finalValue = finalNum
        if(isNaN(finalNum)){
            finalValue = 0
        }
        return Math.ceil(finalValue)
        //return Number(finalNum.toFixed())
    }

    const handleSubmit = async () => {
        if(name === ''){
            toast({
                title: 'Rate name is required!',
                status: 'warning',
                position:'top',
                duration: 2000,
                isClosable: true,
            })
              return
        }
        // Object.keys(rate).forEach(function(key, index){
        //     Object.keys(rate[index]).forEach(function(k, i){
        //         rate[index]['zone'+(i+1)] = computeRate(rateDetail.ratelist[index].rate['zone'+(i+1)], kglist[index], ess[i], fuel, vat, index ) + getAgentRateIndex(index, `zone${i+1}`)
        //     })
        // })

        
        rate.forEach((r,idx)=>{
            zone.zArray.forEach((za,i)=>{
                rate[idx][za] = computeRate(rateDetail.ratelist[idx].rate[za], kglist[idx], ess[i], fuel, vat, idx ) + getAgentRateIndex(idx, za)
            })
        })

        let newList = []
        await Promise.all(rate.map(async (r,idx) => {
            const item = {
                kg: kgSavingList[idx],
                rate: r
            }
            newList.push(item)
        }))
        
        const newRate = {
            name,
            effectDate: effDate ? effDate : new Date().toISOString().substring(0,10),
            rates: newList,
            ess : ess.map((e,i)=>{return {zone: zone.zArray[i], value: Number(e)}}),
            fuel,
            vat,
            goGreen,
            agentRate: {name: agentrate.name, id: agentrate._id}
        }
        dispatch(createRate(newRate))
        //console.log(newRate)
    }

    const handleEssValue = (index, value) => {
        const newEss = ess.map((es, i)=>{
            if(i === index){
                return Number(value)
            } else {
                return es
            }
        })
        setEss(newEss)
    }


    return (
        <Box pt='5'>
            {error && <Error error={error} /> }
            {errorRateName && <Error error={errorRateName} /> }
            {errorAgentRateName && <Error error={errorAgentRateName} /> }
            {errorRateByid && <Error error={errorRateByid} /> }
            {errorAgentRate && <Error error={errorAgentRate} /> }
            {(loadingRateName || 
                loadingAgentRateName ||
                loadingRateById ||
                loadingAgentRate ||
                loading
            ) && <Spinner label='Please wait...' speed='0.1s' thickness='5px' />}
            <HStack spacing={5} mb='30px'>
                <Stack w='200px'>
                    <Box fontWeight={'700'}>Net DHL</Box>
                    <Select 
                        size='sm' 
                        placeholder='Select' 
                        id='rate-id-list' 
                        onChange={(e)=>{
                            if(e.target.value === ''){
                                return
                            }
                            handleGetRateById(e.target.value)
                        }}>
                        <option value=''>Select</option>
                        {ratenames && ratenames.map((rn,idx)=>(
                            <option key={idx} value={rn._id}>{rn.name}</option>
                        ))}
                    </Select>
                </Stack>
                <Stack w='100px'>
                    <Box fontWeight={'700'}>Fuel %</Box>
                    <Input 
                        type='number'
                        id='fuel'
                        size='sm'
                        value={fuel}
                        onChange={e=>setFuel(e.target.value)}
                    />
                </Stack>                
                <Stack w='100px'>
                    <Box fontWeight={'700'}>VAT %</Box>
                    <Input 
                        type='number'
                        id='vat'
                        size='sm'
                        value={vat}
                        onChange={e=>setVat(e.target.value)}
                    />
                </Stack>
                <Stack w='200px'>
                    <Box fontWeight={'700'}>Agent Rate</Box>
                    <Select 
                        size='sm' 
                        id='agent-rate-list' 
                        placeholder='Select' 
                        onChange={(e)=> {
                            if(e.target.value === ''){
                                return
                            }
                            handleGetAgentRateById(e.target.value)
                        }}>
                        <option value=''>Select</option>
                        {agentratenames && agentratenames.map((agrn,idx)=>(
                            <option key={idx} value={agrn._id} disabled={agrn.zoneQty !== zone.zArray.length}>{agrn.name}</option>
                        ))}
                    </Select>
                </Stack>

                <Stack w='200px'>
                    <Box fontWeight={'700'}>ESS Copy</Box>
                    <Select 
                        size='sm' 
                        id='ess-copy-list' 
                        placeholder='Select' 
                        onChange={(e)=> {
                            if(e.target.value === ''){
                                return
                            }
                            const escopy = zone.essCopy[e.target.value].ess
                            const essValue = escopy.map(e=>{return e.value})
                            setEss([...essValue])
                        }}>
                        <option value=''>Select</option>
                        {zone.essCopy.map((essc,idx)=>(
                            <option key={idx} value={idx}>{essc.name}</option>
                        ))}
                    </Select>
                </Stack>

                <Stack w='200px'>
                    <Box fontWeight={'700'}>Rate Name</Box>
                    <Input 
                        type='text'
                        id='rate-name'
                        size='sm'
                        value={name}
                        onChange={e=>setName(e.target.value)}
                    />
                </Stack>
                <Stack w='200px'>
                    <Box fontWeight={'700'}>Effective Date</Box>
                    <Input 
                        type='date'
                        id='eff-date'
                        size='sm'
                        value={effDate}
                        onChange={e=>setEffDate(e.target.value)}
                    />
                </Stack>
                <Stack w='200px'>
                    <Box fontWeight={'700'}>Go Green</Box>
                    <Input 
                        type='number'
                        id='go-green-value'
                        size='sm'
                        value={goGreen}
                        onChange={e=>setGoGreen(e.target.value)}
                    />
                </Stack>
                <Stack>
                    <Button
                        colorScheme={'green'}
                        size='sm'
                        w='100px'
                        paddingY={4}
                        height={'60px'}
                        onClick={handleSubmit}
                        >Save
                    </Button>
                </Stack>

                
            </HStack>
            <Divider />

            
            {/* <Box my='5'>
                <HStack>
                <Box fontWeight={'700'} mr='50px'>ESS </Box>
                {ess.map((es,i)=>(
                    <Box key={i}>
                        <Stack>
                            <Box fontWeight={'700'}>{'zone' + (i+1)}</Box>
                            <Input 
                                type='number'
                                size='sm'
                                w='60px'
                                value={es}
                                onChange={(e)=>handleEssValue(i, e.target.value)}
                            />
                        </Stack>
                    </Box>
                ))}
                <Spacer />
                
                </HStack>
            </Box> */}
            <Box w={'100%'} h={'80vh'} overflow={'scroll'} fontSize={'13px'}>
            <Table size='sm' variant='striped' colorScheme='yellow' mb='30px'>
                <Thead>
                    
                    <Tr>
                        <Th><Box w={'70px'}>KG</Box></Th>
                    {zone.zArray.map((zn) => (
                        <Th key={zn}>{zn.replace('zone','Z')}</Th>
                    ))}
                    </Tr>
                </Thead>
                <Tbody>
                    <Tr border='1px' borderColor='red' pb={2}>
                        <Td>ESS</Td>
                        {ess.map((es,i)=>(
                            <Td key={i} p={1}>
                                <Box>
                                    <Input 
                                        type='number'
                                        id={`ess-${i}`}
                                        textAlign={'center'}
                                        size='sm'
                                        w='60px'
                                        p={0}
                                        value={es}
                                        onChange={(e)=>handleEssValue(i, e.target.value)}
                                    />
                                </Box>
                            </Td>
                        ))}
                    </Tr>
                    {/* {rateDetail && agentrate && rate.forEach(rz=>{
                        console.log(console.log(rz))
                    })} */}
                    {rateDetail && rate.map((rz, idx)=>(
                        <Tr key={idx}>
                            <Td>{kgName[idx]+kglist[idx]}</Td>
                            {zone.zArray.map((za,i)=>(
                                // <Td key={i}>{computeRate(rateDetail.ratelist[idx].rate[za], kglist[idx], ess[i], fuel, vat, idx ) + getAgentRateIndex(idx, za)}</Td>
                                <Td key={i}>{computeRate(rateDetail.ratelist[idx]?.rate[za], kglist[idx], ess[i], fuel, vat, idx ) + getAgentRateIndex(idx, za)}</Td>
                            ))}                            
                        </Tr>
                    ))}
                </Tbody>
            </Table>
            </Box>
        </Box>
    )
}

export default RateCreateScreen