
import React, {useEffect,useRef, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Button, Center, Divider, FormLabel, HStack, Input, Select, Spacer, Spinner, Stack, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'
import { getDhlAccountShipments } from '../actions/shipmentActions'
import Error from '../components/Error'
import ReactToPrint from 'react-to-print'
import { MdLocalPrintshop } from 'react-icons/md'
import { Link } from 'react-router-dom'
import { getDHLAccountList } from '../actions/dhlAccountActions'

const ShipmentDHLAccountReportScreen = () => {
    const dispatch = useDispatch()
    const componentRef = useRef()
    const [ date, setDate ] = useState(new Date().toISOString().substring(0,10))
    const [dhlShipperAccount, setDhlShipperAccount] = useState('')
    const [dhlPayerAccount, setDhlPayerAccount] = useState('')
    const {loading, accounts: dhlAccounts, error } = useSelector(state=>state.dhlAccount.List)
    const {loading: shipmentLoading, error: shipmentError, shipments } = useSelector(state=>state.shipment.DhlAccount)

    const pageStyle = `
        @page {
            size: A4 !important;
            margin: 2mm 2mm;
        }
        html, body {
            font-size: 14px;
            font-weight: 400;
        }
        `;

    useEffect(()=>{
        dispatch(getDHLAccountList())
    },[dispatch])


    return (
        <Center my='50px'>
            <Box w='1200px'>
                <Center>{(loading || shipmentLoading)  && <Spinner label='Please wait...' speed='0.1s' thickness='5px' />}</Center>
                {error && <Error error={error} /> }
                {shipmentError && <Error error={shipmentError} /> }

                

                <Box display={'flex'} justifyContent={'space-between'} gap={3} p={2} border='1px' borderColor={'gray.300'}>
                    <Stack spacing={'0'} flex={1}>
                        <FormLabel>Shipper Account</FormLabel>
                        <Select placeholder='Select' size='sm' onChange={(e)=>setDhlShipperAccount(e.target.value)} >
                            {dhlAccounts && dhlAccounts.map((acc, idx)=>(
                                <option key={idx} value={acc.account}>{acc.account} - {acc.name}</option>
                            ))}
                        </Select>
                    </Stack>

                    <Stack spacing={'0'} flex={1}>
                        <FormLabel>Payer Account</FormLabel>
                        <Select placeholder='Select' size='sm' onChange={(e)=>setDhlPayerAccount(e.target.value)} >
                            {dhlAccounts && dhlAccounts.map((acc, idx)=>(
                                <option key={idx} value={acc.account}>{acc.account} - {acc.name}</option>
                            ))}
                        </Select>
                    </Stack>
                </Box>

                <HStack my='4'>
                    <Box>
                        <Input 
                            type='date'
                            size='sm'
                            w='150px'
                            value={date}
                            onChange={e=>setDate(e.target.value)}
                        />
                    </Box>
                    <Spacer />
                    <Box>   
                        <ReactToPrint
                            trigger={() => <Button size='sm' w='100px' colorScheme='green' leftIcon={<MdLocalPrintshop />}>Print</Button>}
                            content={() => componentRef.current}
                            pageStyle={()=>pageStyle}
                        />
                    </Box>
                    <Button
                        size='sm'
                        colorScheme='blue'
                        w='100px'
                        onClick={()=>{
                            dispatch(getDhlAccountShipments(date, dhlShipperAccount, dhlPayerAccount))
                        }}
                    >Go</Button>
                </HStack>

                
                <Divider />
                <Box ref={componentRef} mt={'50px'}>
                    <Box display={'flex'} justifyContent={'space-between'}>
                        <Box mb='3' fontWeight={'700'} textDecor='underline'>{new Date(date).toDateString()}</Box>
                        <Box mb='3' fontWeight={'700'} textDecor='underline'>Grand Total - {(shipments.reduce((acc,s)=>acc + s.totalAmount, 0))?.toLocaleString()}</Box>
                    </Box>

                    <Table size='sm' variant='striped' colorScheme='black'>
                        <Thead>
                            <Tr>
                                <Th>No</Th>
                                <Th>SM PRE</Th>
                                <Th>AWB</Th>
                                <Th>RECEIVER</Th>
                                <Th>WEIGHT</Th>
                                <Th>SALE</Th>
                                <Th>AGENT</Th>
                                <Th>TOTAL AMOUINT</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {shipments && shipments.map((s,i)=>(
                                <Tr key={i}>
                                    <Td>{i+1}</Td>
                                    <Td><Link to={`/preshipment/${s.preId}`}>{s.preCode}</Link></Td>
                                    <Td><Link to={`/shipment/${s._id}`}>{s.awb}</Link></Td>
                                    <Td>{s.customerDetails.receiverDetails.contactInformation.fullName}</Td>
                                    <Td>{s.totalWeight} kg</Td>
                                    <Td>{s.sale.name}</Td>
                                    <Td>{s.agent.name}</Td>
                                    <Td>{s.totalAmount}</Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>

                </Box>
                

            </Box>
        </Center>
    )
}

export default ShipmentDHLAccountReportScreen