import React, {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, Link, useNavigate } from 'react-router-dom'
import { pltNotAllowedList } from '../data/countries'
import {
    Box,
    Heading,
    HStack,
    Stack,
    FormControl,
    FormLabel,
    Input,
    Button,
    InputRightElement,
    InputGroup,
    Spacer,
    Spinner,
    Divider,
    useToast,
    Select,
    Grid,
    Text,
    UnorderedList,
    ListItem,
    Checkbox,
    Table,
    Thead,
    Tr,
    Th,
    Td,
    Tbody,
    Textarea,
    Switch,
} from '@chakra-ui/react'
import Error from '../components/Error'
import FromAndToDetail from './ShipmentCreateFiles/FromAndToDetail'
// import { MdMenuBook } from 'react-icons/md'
import { createShipment, getPreshipment } from '../actions/shipmentActions'
import { incotermList } from '../data/dhaAccounts'
import { getCostShipment } from '../actions/shipmentActions'
import { SHIPMENT_GET_COST_RESET } from '../constants/shipmentConstants'
//import { PRESHIPMENT_BYID_RESET } from '../constants/shipmentConstants'
import { getDHLAccountList } from '../actions/dhlAccountActions'
import { getChargesList } from '../actions/chargesActions'

const ShipmentCreateScreen = () => {
    const toast = useToast()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const preId = location.search ? location.search.split('=')[1] : ''
    
    const {loading: loadingPreShipment, error: errorPreShipment, preshipment} = useSelector(state=>state.preshipment.ById)
    const {loading: loadingCreate, error: errorCreate, success: successCreate} = useSelector(state=>state.shipment.Create)
    const {loading: loadingGetCost, error: errorGetCost, cost: shipmentCost} = useSelector(state=>state.shipment.GetCost)
    const {loading: loadingDHLAcc, accounts: dhlAccounts, error:errorDHLAcc } = useSelector(state=>state.dhlAccount.List)
    const {loading: loadingCharges, charges: chargesList, error: errorCharges } = useSelector(state=>state.charges.List)

    const [ isDHL, setIsDHL ] = useState(true)
    const [cost, setCost] = useState(0)
    const [takeItemWeight, setTakeItemWeight] = useState(false)
    const [ignoreWeight, setIgnoreWeight] = useState(false)
    const [charges, setCharges] = useState(preshipment && preshipment.charges ? preshipment.charges : [])
    const [newChargeName, setNewChargeName] = useState('')
    const [newChargeAmount, setNewChargeAmount] = useState('')
    const [cusPaidAmount, setCusPaidAmount] = useState(0)
    const [note, setNote] = useState('')
    const [itemList, setItemList] = useState([])
    const [boxList, setBoxList] = useState([])
    const [dhlShipperAccount, setDhlShipperAccount] = useState('')
    const [dhlPayerAccount, setDhlPayerAccount] = useState('')
    const [insurance, setInsurance] = useState(0)
    // const [declaredValueWithInvoice, setDeclaredValueWithInvoice] = useState(0)
    const [shipmentDescription, setShipmentDescription] = useState('')
    const [shipperReference, setShipperReference] = useState('')
    const [shipmentTerm, setShipmentTerm] = useState('DAP')
    const [invoiceFile, setInvoiceFile ] = useState(null)
    const [poaFile, setPoaFile] = useState(null)
    // const [useInvoice, setUseInvoice] = useState(false)
    const [shipperVAT, setShipperVAT] = useState('')
    const [receiverVAT, setReceiverVAT] = useState('')
    const [shipDate, setShipDate] = useState(new Date().toISOString().substring(0,10))
    
    useEffect(()=>{
        if(preId !== ''){
            if(!preshipment || preshipment._id !== preId){
                dispatch(getPreshipment(preId))
            } else {             
                setItemList([...preshipment.items])
                setBoxList([...preshipment.boxes]) 
                setCusPaidAmount(preshipment.paidAmount) 
                setNote(preshipment.note || '')      
                setDhlPayerAccount(preshipment.dhlPayerAccount || '566059294')
                setDhlShipperAccount(preshipment.dhlShipperAccount || '566059294')       
            }            
        }
        if(successCreate){
            toast({
                status: 'success',
                position: 'top',
                title: 'Shipment is created',
                duration: 4000,
                isClosable: true,
            }) 
            navigate('/shipmentsuccess')
        }
        if(shipmentCost){
            setCost(shipmentCost)
        }
        dispatch(getDHLAccountList())
        dispatch(getChargesList())
        return()=>{
            dispatch({type: SHIPMENT_GET_COST_RESET})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[dispatch, preshipment, preId, successCreate, shipmentCost, toast, navigate])

    const handleAddCharges = () => {
        if(newChargeName === '' || newChargeName === 'Select'){return}
        if(newChargeAmount === '' || newChargeAmount === 0){return}
        const existIndex = charges.map(c => c.name).indexOf(newChargeName)
        if(existIndex !== -1){return}
        const newChargeToPush = {
            name: newChargeName,
            amount: Number(newChargeAmount)
        }
        setNewChargeName('')
        setNewChargeAmount('')
        setCharges([...charges, newChargeToPush])
    }
    const handleRemoveCharges = (idx) => {
        const newCharges = charges
        //const existIndex = newCharges.map(c => c.name).indexOf(chargeName)
        newCharges.splice(idx, 1)
        setCharges([...newCharges])
    }
    const fileToBase64 = (file, cb) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = function () {
        cb(null, reader.result)
        }
        reader.onerror = function (error) {
        cb(error, null)
        }
    }
    const onUploadFileChange = ({ target }) => {
        // console.log(typeof target.files)
        // console.log(target.files)
        if (target.files < 1 || !target.validity.valid) {
          return
        }
        fileToBase64(target.files[0], (err, result) => {
          if (result) {
            setInvoiceFile(result)
          }
        })
    }
    const onUploadPOAChange = ({ target }) => {
        if (target.files < 1 || !target.validity.valid) {
          return
        }
        fileToBase64(target.files[0], (err, result) => {
          if (result) {
            setPoaFile(result)
          }
        })
    }

    const handleFindGold = (itemList) => {
        const words = itemList.map(i=>i.item.name)
        var fullWords = words.join(' ').toLowerCase()
        return fullWords.includes('gold plate')
    }
    const handleGetCost = () => {
        if(preshipment === undefined){
            return
        }
        if(preshipment.addressId.countrycode === 'TH'){
            toast({
                status: 'error',
                position: 'top',
                title: 'Receiver country can not be Thailand',
                duration: 2000,
                isClosable: true,
            }) 
            return
        }
        const itemsTotalWeight = itemList.reduce((acc, item) => acc + item.weight, 0)
        const boxesTotalWeight = boxList.reduce((acc, b)=> acc + b.weight, 0)
        let totalWeight
        if(takeItemWeight){
            totalWeight = itemsTotalWeight
        } else {
            totalWeight = boxesTotalWeight
        }
        const data = {
            agentId: preshipment.agentId._id,
            totalWeight,
            productCode: preshipment.productCode,
            countryCode: preshipment.addressId.countrycode
        }
        if(data.totalWeight === 0){
            toast({
                status: 'error',
                position: 'top',
                title: 'Total weight can not be 0',
                duration: 4000,
                isClosable: true,
            }) 
            return
        }
        if(data.productCode === 'D' && data.totalWeight > 2){
            toast({
                status: 'error',
                position: 'top',
                title: 'Document weight can not be more than 2 kg',
                duration: 4000,
                isClosable: true,
            }) 
            return
        } else {
            // console.log(data)
            dispatch(getCostShipment(data))
        }
        
    }
    const removeEmpty = (obj) => {
        return Object.entries(obj)
            .filter(([_, v]) => v != null)
            .reduce(
                (acc, [k, v]) => ({ ...acc, [k]: v === Object(v) ? removeEmpty(v) : v }),
                {}
            )
    }
    // const handleInsuranceChange = event => {
        
    //     let { value, min, max } = event.target;
    //     //console.log(value, min, max)
    //     value = Math.max(Number(min), Math.min(Number(max), Number(value)));
    
    //     setInsurance(value);
    // };




    const handleShip = (e) => {
        e.preventDefault()
        if(isDHL && preshipment.productCode === 'P' && invoiceFile === null){
            toast({
                status: 'warning',
                position: 'top',
                title: 'Please attach invoice',
                duration: 4000,
                isClosable: true,
            }) 
            return
        }
        // if(useInvoice && (declaredValueWithInvoice === '0' || declaredValueWithInvoice === 0)){
        //     toast({
        //         status: 'warning',
        //         position: 'top',
        //         title: 'Inovice value can not be 0',
        //         duration: 4000,
        //         isClosable: true,
        //     }) 
        //     return
        // }
        if(cost === 0 || cost === '0'){
            toast({
                status: 'warning',
                position: 'top',
                title: 'Shipment cost can not be 0',
                duration: 4000,
                isClosable: true,
            }) 
            return
        }
        const boxTotalWeight = Number(boxList.reduce((acc, box) => acc + box.weight, 0))
        const itemTotalWeight = Number(itemList.reduce((acc, item) => acc + item.weight, 0))
        if(isDHL && !ignoreWeight && (Math.abs(boxTotalWeight - itemTotalWeight)) >= 2){
            toast({
                status: 'warning',
                position: 'top',
                title: 'Boxs total weight and items total weight different more than 1 kg',
                duration: 4000,
                isClosable: true,
            }) 
            return            
        }
        const plannedShippingDateAndTime = shipDate + 'T23:59:00GMT+07:00'
        const pickup = {isRequested: false}
        const productCode = preshipment.productCode
        const localProductCode = preshipment.productCode
        const getRateEstimates = false
        //account
        const accounts = [
            {typeCode: 'shipper', number: dhlShipperAccount},
            {typeCode: 'payer', number: dhlPayerAccount}
        ]


        //value added services
        var valueAddedServices = [{serviceCode: 'WY'}]
        const pltIndex = pltNotAllowedList.indexOf(preshipment.addressId.countrycode)
        
        if(pltIndex !== -1 || preshipment.productCode === "D"){
            valueAddedServices.shift()
        }

        if(insurance > 0){
            valueAddedServices.push({serviceCode: 'II', value: Number(insurance), currency: preshipment.currency  })
        }


        const outputImageProperties = {
            printerDPI: 300,
            encodingFormat: "pdf",            
            imageOptions: [
              {
                typeCode: "waybillDoc",
                templateName: "ARCH_8X4",
                isRequested: preshipment.productCode === 'D' ? true : false,
              },
               {
                typeCode: "invoice",
                isRequested: false,
                // isRequested: preshipment.productCode === 'P' ? true : false,
                invoiceType:"proforma"
              }
            ]
        }
        
        //customer detail (shipper, receiver) (customerDetails)
        const customerDetailsToCheck = {
            shipperDetails: preshipment.isVplus ? {
                postalAddress: {
                    postalCode: '63110',
                    cityName: 'MAESOT',
                    countryCode: 'TH',
                    provinceCode: 'TAK',
                    addressLine1: '2456 MOO.1 THASAILUAT',
                    countryName: 'THAILAND',
                },
                contactInformation: {
                    email: null,
                    phone: '+' + preshipment.fromId.phonecode + preshipment.fromId.phone,
                    companyName: 'SIAM MOEI CO.,LTD',
                    // fullName: preshipment.fromId.name,
                    fullName: preshipment.shipper ? preshipment.shipper : preshipment.agentId?.name ?? preshipment.fromId.name,
                }
            } : {
                postalAddress: {
                    postalCode: preshipment.fromId.postalcode,
                    cityName: preshipment.fromId.city,
                    countryCode: preshipment.fromId.countrycode,
                    provinceCode: preshipment.fromId.stateProvince || null,
                    addressLine1: preshipment.fromId.address,
                    addressLine2: preshipment.fromId.address2 || null,
                    addressLine3: preshipment.fromId.address3 || null,
                    countryName: preshipment.fromId.country,
                },
                contactInformation: {
                    email: preshipment.fromId.email || null,
                    phone: '+' + preshipment.fromId.phonecode + preshipment.fromId.phone,
                    companyName: preshipment.fromId.company,
                    // fullName: preshipment.fromId.name,
                    fullName: preshipment.shipper ? preshipment.shipper : preshipment.agentId?.name ?? preshipment.fromId.name,
                }
            },
            receiverDetails: {
                postalAddress: {
                    postalCode: preshipment.addressId.postalcode,
                    cityName: preshipment.addressId.city,
                    countryCode: preshipment.addressId.countrycode,
                    provinceCode: preshipment.addressId.stateProvince || null,
                    addressLine1: preshipment.addressId.address,
                    addressLine2: preshipment.addressId.address2 || null,
                    addressLine3: preshipment.addressId.address3 || null,
                    countryName: preshipment.addressId.country,
                },
                contactInformation: {
                    email: preshipment.addressId.email || null,
                    phone: '+' + preshipment.addressId.phonecode + preshipment.addressId.phone,
                    companyName: preshipment.addressId.company,
                    fullName: preshipment.addressId.name,
                }
            }
        }

        const customerDetails = removeEmpty(customerDetailsToCheck)
        if(shipperVAT !== ''){
            customerDetails.shipperDetails['registrationNumbers'] = [
                {
                    typeCode :"VAT",
                    number : shipperVAT,
                    issuerCountryCode :"TH"
                }
            ]
        }
        if(receiverVAT !== ''){
            customerDetails.receiverDetails['registrationNumbers'] = [
                {
                    typeCode :"VAT",
                    number : receiverVAT,
                    issuerCountryCode : customerDetails.receiverDetails.postalAddress.countryCode
                }
            ]
        }

        //content (weight dimensions of each boxes)(content)
        let content 
        if(preshipment.productCode === 'P'){            
            content = {
                packages: [...boxList],
                isCustomsDeclarable: true,
                declaredValue: Math.round((itemList.reduce((acc, item) => acc + item.price * item.qty, 0))*100)/100,
                declaredValueCurrency: preshipment.currency,
                //item detail and list (exportDeclaration)
                exportDeclaration: {
                    lineItems: 
                        itemList.map((item,idx) => {
                            const newToPush = {
                                number: idx+1,
                                description: item.item.name,
                                //description: (item.item.name + '-' + item.item.manuName + '-' + item.item.manuAddress).replace(/(\r\n\t|\n|\r|\t)/gm, "").substring(0, 200),
                                price: item.price || 0,
                                quantity:{
                                    value: item.qty,
                                    unitOfMeasurement: "PCS"
                                },
                                exportReasonType: "permanent",
                                manufacturerCountry: item.item.manuCountry,
                                weight: { 
                                    netValue: item.weight,
                                    grossValue: item.weight
                                },
                                // commodityCodes: [{
                                //     typeCode:'outbound',
                                //     value:item.item.hsCode ?? '',
                                // }]
                            }  
                            if(item.item.hsCode){
                                newToPush['commodityCodes'] = [
                                    {
                                        typeCode:'outbound',
                                        value:item.item.hsCode,
                                    },
                                    {
                                        typeCode:'inbound',
                                        value:item.item.hsCode,
                                    }
                                ]
                            }
                            return newToPush               
                        }),
                    invoice: {
                        number: preshipment.dhlInvoiceNo,
                        date: new Date().toISOString().substring(0,10),
                        // totalNetWeight: 0,
                        // totalGrossWeight: 0,
                    }
                },
                description: shipmentDescription || '-', // needed
                incoterm: shipmentTerm,
                unitOfMeasurement: 'metric',
            }            
            
        } 
        if(preshipment.productCode === 'D') {
            content = {
                packages: [...boxList],
                isCustomsDeclarable: false,
                description: shipmentDescription || '-', // needed
                incoterm: shipmentTerm,
                unitOfMeasurement: 'metric',
            }
        }
        
       
        const shipmentData = {
            plannedShippingDateAndTime,
            pickup,
            productCode,
            localProductCode,
            getRateEstimates,
            accounts,
            valueAddedServices,
            customerReferences: [{value: preshipment.code, typeCode: 'CU'}],
            outputImageProperties,
            customerDetails,
            content,
            documentImages: preshipment.productCode === 'P' ? [
                {
                    typeCode: 'PNV',
                    imageFormat: 'PDF',
                    content: isDHL ? invoiceFile.substring('data:application/pdf;base64,'.length) : ''
                }
            ] : []
        }    
        const computedTotalWeight = Math.round((boxList.reduce((acc, box) => acc + box.weight, 0))*100)/100
        //const updatedCharges = [...charges, {name: 'Go Green Plus', amount: computedTotalWeight*6}]
        const updatedCharges = [...charges]
        const computedTotalAmount = Math.round(updatedCharges.reduce((acc, ch) => acc + ch.amount, 0)) + Number(cost)
        const smData = {      
            isDHL,      
            preId: preshipment._id, //needed in backend
            preCode: preshipment.code,
            totalWeight: computedTotalWeight,
            totalAmount: computedTotalAmount,
            paidAmount: cusPaidAmount,            
            charges:updatedCharges,
            useInvoice: preshipment.productCode === 'P' ? true : false,            
            note,
            isVplus: preshipment.isVplus,
            invoiceFile: preshipment.productCode === 'P' ? {
                typeCode: 'invoice',
                imageFormat: 'PDF',
                content: isDHL ? invoiceFile.substring('data:application/pdf;base64,'.length) : ''
            } : {}
        }

        // if(useInvoice){
        //     shipmentData.documentImages.push({
        //         typeCode: 'PNV',
        //         imageFormat: 'PDF',
        //         content: invoiceFile.substring('data:application/pdf;base64,'.length)
        //     })
        //     smData.invoiceFile= {
        //         typeCode: 'invoice',
        //         imageFormat: 'PDF',
        //         content: invoiceFile.substring('data:application/pdf;base64,'.length)
        //     }
        //     smData.exportDeclaration= {
        //         lineItems: 
        //             itemList.map((item,idx) => {
        //                 const newToPush = {
        //                     number: idx+1,
        //                     description: item.item.name,
        //                     price: item.price || 0,
        //                     quantity:{
        //                       value: item.qty,
        //                       unitOfMeasurement: "PCS"
        //                     },
        //                     exportReasonType: "permanent",
        //                     manufacturerCountry: item.item.manuCountry,
        //                     weight: { 
        //                       netValue: item.weight,
        //                       grossValue: item.weight
        //                     }
        //                 }  
        //                 return newToPush               
        //             })
        //     }
        //     //outputImageProperties.imageOptions[1].isRequested = false
        // }
        
        if(customerDetails.receiverDetails.postalAddress.countryCode === 'CA' && preshipment.productCode === 'P'){
            shipmentData.documentImages.push({
                typeCode: 'INV',
                imageFormat: 'PDF',
                content: isDHL ? poaFile.substring('data:application/pdf;base64,'.length) : ''
            })
        }

        //console.log(shipmentData, smData)
        dispatch(createShipment(shipmentData, smData))
    }





    return (
        <Box>  
            <Box mx='5%'>
                {(loadingPreShipment || loadingCharges || loadingDHLAcc || loadingCreate) && <Spinner label='Please wait...' speed='0.1s' thickness='5px' />}  
               
                {errorPreShipment && <Error error={errorPreShipment} />}  
                {errorCreate && <Error error={errorCreate} />}  
                {errorDHLAcc && <Error error={errorDHLAcc} /> }
                {errorCharges && <Error error={errorCharges} /> }
                
            </Box>  
            
            <Box  mx='5%' opacity={loadingCreate && '0.5'}> 

                <Box p={3} boxShadow={'dark-lg'} display={'flex'} justifyContent={'space-between'} alignItems={'center'} my={3}>
                <Box fontSize={'2xl'}>USE DHL API</Box>
                <Box>
                    <Switch 
                        size='lg'
                        isChecked={isDHL} 
                        id={'dhl-switch'} 
                        onChange={()=>{
                            setIsDHL(prev=>!prev)                                    
                        }} 
                    />
                </Box>
            </Box>

            <Heading mb='5'>Addresses</Heading>
            <FromAndToDetail preshipment={preshipment} />
            <Box h='20px'></Box>
            <Grid
            templateRows='repeat(1, 1fr)'
            templateColumns='repeat(2, 1fr)'
            gap={4}
            >
                <FormControl>
                    <FormLabel fontSize='sm'>Shipper VAT/Tax ID</FormLabel>
                    <Input 
                        type='text' 
                        size='sm'
                        w='300px'
                        value={shipperVAT}
                        onChange={e=>setShipperVAT(e.target.value)}
                    />
                </FormControl>
                <FormControl>
                    <FormLabel fontSize='sm'>Receiver VAT/Tax ID</FormLabel>
                    <Input 
                        type='text' 
                        size='sm'
                        w='300px'
                        value={receiverVAT}
                        onChange={e=>setReceiverVAT(e.target.value)}
                    />
                </FormControl>
            </Grid>
            
            
            <Box>
            <Box h='10px' bg='green.600' my='30px' borderRadius={'20'}></Box>
                <Heading mb='5'>Shipment Detail</Heading>
                <Grid
                    templateRows='repeat(1, 1fr)'
                    templateColumns='repeat(4, 1fr)'
                    gap={4}
                    >

                    <FormControl colSpan={2}>
                    <FormLabel>Ship</FormLabel>
                        <Input 
                            type='text' 
                            readOnly
                            size='sm'                               
                            value={preshipment && preshipment.productCode === 'D' ? 'Document' : 'Package'}
                        />
                    </FormControl>

                    <FormControl colSpan={2}>
                        <Stack spacing={'0'}>
                            <FormLabel>Ship Date</FormLabel>
                            <Input 
                            type='date' 
                            size='sm'                               
                            value={shipDate}
                            onChange={e=>setShipDate(e.target.value)}
                            />
                            <Box style={{'fontSize': '11px', 'color': 'red'}}>(Default: Today)</Box>
                        </Stack>
                    </FormControl>
                    
                    {/* <FormControl colSpan={2}>
                    <FormLabel>Invoice No</FormLabel>
                        <Input
                            type='text'
                            readOnly
                            size='sm'
                            value={invoiceNo}
                        />
                    </FormControl> */}

                    <FormControl colSpan={2}>
                        <Stack spacing={'0'}>
                            <FormLabel>Shipper Account</FormLabel>
                            <Select placeholder='Select' size='sm' value={dhlShipperAccount} onChange={(e)=>setDhlShipperAccount(e.target.value)} >
                                {dhlAccounts && dhlAccounts.map((acc, idx)=>(
                                    <option key={idx} value={acc.account}>{acc.account} - {acc.name}</option>
                                ))}
                            </Select>
                            <Box style={{'fontSize': '11px', 'color': 'red'}}>(Default: Siam Max Account)</Box>
                        </Stack>
                    </FormControl>

                    <FormControl colSpan={2}>
                        <Stack spacing={'0'}>
                            <FormLabel>Payer Account</FormLabel>
                            <Select placeholder='Select' value={dhlPayerAccount} size='sm' onChange={(e)=>setDhlPayerAccount(e.target.value)} >
                                {dhlAccounts && dhlAccounts.map((acc, idx)=>(
                                    <option key={idx} value={acc.account}>{acc.account} - {acc.name}</option>
                                ))}
                            </Select>
                            <Box style={{'fontSize': '11px', 'color': 'red'}}>(Default: Siam Max Account)</Box>
                        </Stack>
                    </FormControl>

                    <FormControl colSpan={2}>
                    <FormLabel>Insurance Amount</FormLabel>
                        <InputGroup>
                        <Input
                            type='number'
                            size='sm'
                            // min='1'
                            // max='9000'
                            value={insurance}
                            onChange={(e)=>setInsurance(e.target.value)}
                        />
                        <InputRightElement><Text fontSize={'sm'} h='2rem' >{preshipment && preshipment.currency}</Text></InputRightElement>
                        </InputGroup>
                    </FormControl>

                    <FormControl colSpan={2}>
                        <Stack spacing={'0'}>
                            <FormLabel>Shipment Description </FormLabel>
                            <Input
                                type='text'
                                size='sm'
                                maxLength={70}
                                value={shipmentDescription}
                                onChange={(e)=>setShipmentDescription(e.target.value)}
                            />                        
                            <Box style={{'fontSize': '11px', 'color': 'red'}}>({70-shipmentDescription.length} character left)</Box>
                        </Stack>
                    </FormControl>

                    <FormControl colSpan={2}>
                        <Stack spacing={'0'}>
                            <FormLabel>Shipper Reference </FormLabel>
                            <Input
                                type='text'
                                size='sm'
                                maxLength={35}
                                value={shipperReference}
                                onChange={(e)=>setShipperReference(e.target.value)}
                            />
                            <Box style={{'fontSize': '11px', 'color': 'red'}}>({35-shipperReference.length} character left)</Box>
                        </Stack>
                    </FormControl>

                    <FormControl colSpan={2}>
                        <Stack spacing={'0'}>
                            <FormLabel>Terms </FormLabel>
                            <Select placeholder='DAP - Delivered at Place' size='sm' onChange={(e)=>setShipmentTerm(e.target.value)} >
                                {incotermList.map((term, idx)=>(
                                    <option key={idx} value={term.code}>{term.code} - {term.description}</option>
                                ))}
                            </Select>
                            <Box style={{'fontSize': '11px', 'color': 'red'}}>(Default: DAP)</Box>
                        </Stack>
                    </FormControl>
                </Grid>
            </Box>

            { preshipment && preshipment.productCode === 'P' && 
                <Box>
                    <Box h='10px' bg='cyan.600' my='30px' borderRadius={'20'}></Box>
                    <Stack direction={'row'} spacing='5' mb='5'>
                        <Heading >Items and Invoice</Heading>
                        {/* <Switch aria-label='Upload File' py='3' colorScheme='teal' size='lg' onChange={()=>setUseInvoice(!useInvoice)} />
                        <Heading >Invoice Upload</Heading> */}
                    </Stack>
                    
                   
                        <Stack spacing={'3'} direction='row' my='4'>
                            <Stack p='4' border='2px' borderRadius={'10'} borderColor={invoiceFile === null ? 'red' : 'green'}>
                                <Box>Invoice attach</Box>
                                    <input 
                                    type='file' 
                                    name='filetobase64' 
                                    onChange={onUploadFileChange} 
                                    accept='application/pdf'
                                />
                            </Stack>
                            {/* <Stack>
                                <Box>Invoice Value <span style={{'color': 'red'}}> ({preshipment.currency})</span></Box>
                                <Input 
                                    type='number'
                                    size='sm'
                                    value={declaredValueWithInvoice}
                                    onChange={(e)=>setDeclaredValueWithInvoice(e.target.value)}
                                />
                            </Stack> */}
                        </Stack>
                        
                        <Stack>
                        <Table variant='simple' size='sm'>
                            <Thead>
                            <Tr>
                                <Th>No</Th>
                                <Th>Item Name</Th>
                                <Th isNumeric>Weight</Th>
                                <Th isNumeric>Price</Th>
                                <Th isNumeric>Qty</Th>
                                <Th isNumeric>Amount</Th>
                            </Tr>
                            </Thead>

                            <Tbody>
                            {preshipment && itemList.map((item,idx)=>(
                                <Tr key={idx}>
                                    <Td>{idx+1}</Td>
                                    <Td>
                                        <Box>{item.item.description}</Box>
                                        {!item.item.hsCode && <Box color='red'>Missing HS Code</Box>}
                                    </Td>
                                    <Td isNumeric>{item.weight}</Td>
                                    <Td isNumeric>{item.price}</Td>
                                    <Td isNumeric>{item.qty}</Td>
                                    <Td isNumeric>{(item.price*item.qty).toFixed(2)}</Td>
                                </Tr>
                            ))}
                            
                            </Tbody>
                        </Table>
                                        
                            <HStack pe='30px'>
                                <Box w='200px'></Box>
                                <Spacer />
                                <FormLabel fontSize={'lg'}>Total Weight - 
                                    <span style={{'backgroundColor': 'yellow', 'padding': '0 5px', 'borderRadius': '10px'}}> 
                                        {(itemList.reduce((acc, item) => acc + item.weight, 0)).toFixed(2)} 
                                    </span>kg
                                </FormLabel> 
                                <Spacer />
                                <FormLabel fontSize={'lg'}>Total Amount - 
                                    {(itemList.reduce((acc, item) => acc + item.price * item.qty, 0)).toFixed(2)} 
                                    ({preshipment.currency})
                                </FormLabel>
                            </HStack>
                        </Stack>
                </Box>
            }

            

            <Box>
                <Box h='10px' bg='purple.600' my='30px' borderRadius={'20'}></Box>
                <Heading mb='5'>{preshipment && preshipment.productCode === "D" ? "Document" : "Boxes"}</Heading>
                <Stack>
                    {preshipment && boxList.map((box,idx)=>(
                        <Box key={idx} pe='30px'>
                            <HStack mb='2'>
                                <Box mr='30px'>Box {idx + 1} -</Box>
                                <Box>{box.dimensions.length} x {box.dimensions.width} x {box.dimensions.height} cm</Box>
                                <Spacer />
                                <Box>{box.weight} kg</Box>
                            </HStack>
                            {/* <HStack mb='2'>
                                <Stack spacing={'0'} w='100px'>
                                    <Box>Length</Box>
                                    <Input 
                                        readOnly
                                        type='number'
                                        size='sm'
                                        value={box.dimensions.length}
                                        onChange={(e)=>handleBoxEdit(idx, e.target.value, 'length')}
                                    />
                                </Stack>
                                <Stack spacing={'0'} w='100px'>
                                    <Box>Width</Box>
                                    <Input 
                                        readOnly
                                        type='number'
                                        size='sm'
                                        value={box.dimensions.width}
                                        onChange={(e)=>handleBoxEdit(idx, e.target.value, 'width')}
                                    />
                                </Stack>
                                <Stack spacing={'0'} w='100px'>
                                    <Box>Height</Box>
                                    <Input 
                                        readOnly
                                        type='number'
                                        size='sm'
                                        value={box.dimensions.height}
                                        onChange={(e)=>handleBoxEdit(idx, e.target.value, 'height')}
                                    />
                                </Stack>
                                <Spacer />
                                <Stack spacing={'0'} w='100px'>
                                    <Box>Weight</Box>
                                    <Input 
                                        readOnly
                                        type='number'
                                        size='sm'
                                        value={box.weight}
                                        onChange={(e)=>handleBoxWeight(idx, e.target.value)}
                                    />
                                </Stack>
                            </HStack>  */}
                            <Divider />                           
                        </Box>
                    ))}
                    <HStack pe='30px'>
                        <Box></Box>
                        <Spacer />
                        <FormLabel fontSize={'lg'}>Total - {(boxList.reduce((acc, box) => acc + box.weight, 0)).toFixed(2)} kg</FormLabel>
                    </HStack>
                </Stack>
            </Box>
            

            <Box>
                <Box h='10px' bg='orange.600' my='30px' borderRadius={'20'}></Box>
                <Heading mb='2'>SM Detail</Heading>
                {preshipment && <Box className='quadrat' mb='3' fontSize='lg' fontWeight={'600'}>{preshipment.note}</Box>}
                {/* {preshipment && preshipment.isRemoteArea && <Box className='quadrat' mb='3' fontSize='lg' fontWeight={'600'}>Remote Area</Box>} */}
                {errorGetCost && <Error error={errorGetCost} />}  
                
                <Stack>

                <Grid
                    templateRows='repeat(1, 1fr)'
                    templateColumns='repeat(4, 1fr)'
                    gap={4}
                    >
                    <FormControl colSpan={2}>
                        <Stack>
                            <Box>
                                <Box>Charges</Box>
                                <HStack>
                                    <Select placeholder='Select' size='sm' onChange={(e)=>setNewChargeName(e.target.value)} >
                                        {chargesList && chargesList.map((c, idx)=>(
                                            <option key={idx} value={c.name}>{c.name}</option>
                                        ))}
                                    </Select>
                                    <Input 
                                        type='number'
                                        size='sm'
                                        w='100px'
                                        value={newChargeAmount}
                                        onChange={(e)=>setNewChargeAmount(e.target.value)}
                                    />
                                    <Button
                                        size='sm'
                                        colorScheme={'green'}
                                        onClick={handleAddCharges}
                                    >Add</Button>
                                </HStack>
                            </Box>
                            <Box>
                                <UnorderedList spacing={3}>
                                    {charges.map((ch, idx)=>(
                                        <ListItem key={idx}>
                                            <HStack>
                                                <Box w='200px'>{ch.name}</Box>
                                                <Box>{ch.amount}</Box>
                                                <Spacer />
                                                <Button
                                                    size='xs'
                                                    colorScheme={'red'}
                                                    onClick={()=>handleRemoveCharges(idx)}
                                                >
                                                    x
                                                </Button>
                                            </HStack>
                                        </ListItem>
                                    ))}
                                </UnorderedList>
                            </Box>
                        </Stack>
                    </FormControl>

                    <Stack spacing={'0'} >
                        <Box>Cost <span style={{'color': 'red', 'fontSize': '12px'}}> default: box weight</span></Box>                        
                        <HStack mb='3'>
                        <Input 
                            type={'text'}
                            size='sm'
                            readOnly
                            value={cost}
                            onChange={(e)=>setCost(e.target.value)}
                        />
                        <Button
                            size='sm'
                            isLoading={loadingGetCost}
                            disabled={preshipment === undefined}
                            colorScheme={'green'}
                            onClick={handleGetCost}
                        >
                            Compute
                        </Button>
                        </HStack>
                        <Stack>
                            <Checkbox onChange={()=>setIgnoreWeight(!ignoreWeight)}>Ignore Weight</Checkbox>
                            <Checkbox onChange={()=>setTakeItemWeight(!takeItemWeight)}>Item Weight</Checkbox>
                        </Stack>
                        
                        
                    </Stack>


                    <Stack spacing={'0'} >
                        <Box>Customer Paid</Box>                        
                        <HStack>
                            <Input 
                                type={'text'}
                                size='sm'
                                value={cusPaidAmount}
                                onChange={(e)=>setCusPaidAmount(e.target.value)}
                            />
                        </HStack>
                    </Stack>
                    <Stack spacing={'0'} >
                        <Box>Note</Box>                        
                        <HStack>
                            <Textarea 
                                type={'text'}
                                size='sm'
                                h='80px'
                                value={note}
                                onChange={(e)=>setNote(e.target.value)}
                            />
                        </HStack>
                    </Stack>
                </Grid>
                    
                    <Divider />
                    <HStack pe='30px'>
                        <Box></Box>
                        <Spacer />
                        <FormLabel fontSize={'2xl'}>
                        {charges.length !== 0 ? (charges.reduce((acc, ch) => acc + ch.amount, 0) + Number(cost)) : Number(cost)}
                        <span style={{'fontSize': '15px', 'color': 'red'}}> thb</span>
                        </FormLabel>
                    </HStack>
                </Stack>
            </Box>

            {(  preshipment && 
                preshipment.addressId.countrycode === 'CA' &&
                preshipment.productCode === 'P') &&
            <Box w='100%' border='1px' borderColor={poaFile === null ? 'red.300' : 'green.300'} p='2'>
                <Stack>
                    <Box fontWeight={500}>Power of Attorney File</Box>
                        <input 
                        type='file' 
                        name='filetobase64' 
                        onChange={onUploadPOAChange} 
                        accept='application/pdf'
                    />
                </Stack> 
            </Box>
            }
            

            </Box> 
                <Box mx='5%' my='5'>
                
                {preshipment && preshipment.items.map(itm=>itm.item.hsCode).filter(x=>x !== '').length !== preshipment.items.length && <Error error='Some items missing HS Code.' /> }
                {preshipment && preshipment.isRemoteArea && !charges.some(x=>x.name === 'Remote Area') && <Error error='Need remote area charges' />}
                {(preshipment && (insurance === 0 || insurance === '') && handleFindGold(preshipment.items)) && <Error error = 'Gold Plate is in item list, Insurance is needed.' />}
                {errorCreate && <Error error={errorCreate} />}  
                {preshipment && preshipment.productCode === 'P' && invoiceFile === null && <Error error={'Inovice is not attached yet.'} />}
                {!ignoreWeight && preshipment && preshipment.productCode === "P" && Math.abs(itemList.reduce((acc, item) => acc + item.weight, 0) - boxList.reduce((acc, box) => acc + box.weight, 0)) >= 2 &&
                    <Error error={'Item total weight and box total weight different is more than 2 kg.'} />
                }
                { cost === 0 && <Error error={'Shipment cost can not be 0.'} />}
                { ( preshipment && 
                    preshipment.addressId.countrycode === 'CA' && 
                    preshipment.productCode === 'P' && 
                    poaFile === null) &&
                    <Error error={'Need POA file for Canada shipment.'} />
                }
                    <Divider />
                    <HStack mt='3'>

                        <Link to='/shipment'>
                            <Button
                                size='sm'
                                isLoading={loadingCreate}
                                w='150px'
                                colorScheme={'blue'}
                            >Cancel</Button>
                        </Link>
                        <Spacer />
                        <Button
                            isDisabled={preshipment && 
                                preshipment.addressId.countrycode === 'CA' && 
                                preshipment.productCode === 'P' && 
                                poaFile === null}
                            size='sm'
                            isLoading={loadingCreate}
                            w='150px'
                            colorScheme={'yellow'}
                            onClick={handleShip}
                        >Ship</Button>
                    </HStack>
                </Box>
        </Box>
    )
}

export default ShipmentCreateScreen