import React, {useEffect,useRef, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Button, Center, Divider, Heading, HStack, Input, Spacer, Spinner, Table, Tbody, Td, Th, Thead, Tr, Select, Stack, Text } from '@chakra-ui/react'
import { listPreshipment } from '../actions/shipmentActions'
import Error from '../components/Error'
import ReactToPrint from 'react-to-print'
import { MdLocalPrintshop } from 'react-icons/md'
import { listAgent } from '../actions/userActions'

const ShipmentPreReportScreen = () => {
    const dispatch = useDispatch()
    const componentRef = useRef()
    const [ date, setDate ] = useState(new Date().toISOString().substring(0,10))
    const [filterAgent, setFilterAgent] = useState('')
    const {loading, error, preshipments} = useSelector(state=>state.preshipment.List)
    const {loading: loadingAgents, error: errorAgents, agents} = useSelector(state=>state.user.AgentList)

    const pageStyle = `
        @page {
            size: A4 !important;
            margin: 2mm 2mm;
        }
        html, body {
            font-size: 14px;
            font-weight: 400;
        }
        `;
    
    useEffect(()=>{
        dispatch(listAgent('', 1000))
    },[dispatch])
    useEffect(()=>{
        dispatch(listPreshipment('', filterAgent, date, ''))
        // dispatch(listPreshipment(keyword, filterAgent, filterDate, filterShipped))
    },[dispatch, filterAgent, date])

    const uniqueArray = a => [...new Set(a.map(o => JSON.stringify(o)))].map(s => JSON.parse(s))

    return (
        <Center my='50px'>
            <Box w='900px'>
                <Center>{loading  && <Spinner label='Please wait...' speed='0.1s' thickness='5px' />}</Center>
                {error && <Error error={error} /> }
                {errorAgents && <Error error={errorAgents} /> }
                <HStack my='4'>
                    <Box>
                        <Input 
                            type='date'
                            id={'filter-date'}
                            size='sm'
                            w='150px'
                            value={date}
                            onChange={e=>setDate(e.target.value)}
                        />
                    </Box>
                    {loadingAgents && <Spinner />}
                    <Select placeholder='Select' id={'ag-select'} size='sm' w='full' onChange={e=>setFilterAgent(e.target.value)}>
                        <option value={''}>All</option>
                        {preshipments && uniqueArray([...preshipments.map(x=>x.agentId)]).map((psa, idx)=> (
                            <option key={idx} value={psa._id}>{psa.name} : {psa.agentCode}</option>
                        ))}
                    </Select>
                    <Spacer />
                    <Box>   
                        <ReactToPrint
                            trigger={() => <Button size='sm' w='100px' colorScheme='green' leftIcon={<MdLocalPrintshop />}>Print</Button>}
                            content={() => componentRef.current}
                            pageStyle={()=>pageStyle}
                        />
                    </Box>
                </HStack>
                <Divider />
                <Box ref={componentRef}>
                    <Center mt='30px'>
                        <Heading>Pre Summary</Heading>                        
                    </Center>
                    <Box mb='3' fontWeight={'700'} textDecor='underline'>{new Date(date).toDateString()}</Box>

                    <Table size='sm' variant='striped' colorScheme='black'>
                        <Thead>
                            <Tr>
                                <Th w={'30px'}>No</Th>
                                <Th>CODE</Th>
                                <Th>SHIPPER</Th>
                                <Th>RECEIVER</Th>
                                <Th maxW='70px'>KG</Th>
                                <Th maxW='70px'>SALE</Th>
                                <Th maxW='100px'>AGENT</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {preshipments && preshipments.map((ps,i)=>(
                                <Tr key={i}>
                                    <Td w={'30px'}>{i+1}</Td>
                                    <Td>{ps.code}</Td>
                                    <Td>
                                        <Text>{ps.agentId.sale.addressId.name ?? "no shipper name"}</Text>                                       
                                    </Td>
                                    <Td>
                                        <Box>
                                            <Text lineHeight={'10px'} pb={0}>{ps.addressId.name ?? ps.addressId.name}</Text>
                                            <Text fontSize={'12px'}>
                                                {ps.addressId.address ?? ps.addressId.address}, 
                                                {ps.addressId.city ?? ps.addressId.city}, 
                                                {ps.addressId.countrycode ?? ps.addressId.countrycode}

                                            </Text>
                                        </Box>
                                    </Td>
                                    <Td maxW='70px' p={0} textAlign={'center'}>{ps.boxes.reduce((acc, b) => acc + b.weight, 0)}</Td>
                                    <Td maxW='70px' p={0} textAlign={'start'}>{ps.agentId.sale.saleCode}</Td>
                                    <Td maxW='100px' p={0} textAlign={'center'}>{ps.agentId.agentCode}</Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>

                </Box>
                

            </Box>
        </Center>
    )
}

export default ShipmentPreReportScreen