import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Error from "../components/Error";
import {
  HStack,
  Stack,
  Input,
  Button,
  Box,
  Spacer,
  Spinner,
  Divider,
  Select,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from "@chakra-ui/react";
import { listAgent, listSale } from "../actions/userActions";
import { pullDataShipments } from "../actions/shipmentActions";
import * as XLSX from "xlsx";

const PullDataScreen = () => {
  const dispatch = useDispatch();
  const [agentSearchKeyword, setAgentSearchKeyword] = useState("");
  const [agentId, setAgentId] = useState("");
  const [saleSearchKeyword, setSaleSearchKeyword] = useState("");
  const [saleId, setSaleId] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const { loading, error, shipments } = useSelector(
    (state) => state.shipment.List
  );
  const {
    loading: loadingAgentList,
    error: errorAgentList,
    agents,
  } = useSelector((state) => state.user.AgentList);
  const {
    loading: loadingSaleList,
    error: errorSaleList,
    sales,
  } = useSelector((state) => state.user.SaleList);

  const handleSearchAgent = (e) => {
    if (e.key === "Enter") {
      dispatch(listAgent("", 1000, agentSearchKeyword, saleId));
    }
    return;
  };
  const handleSearchSale = (e) => {
    if (e.key === "Enter") {
      dispatch(listSale(saleSearchKeyword));
    }
    return;
  };

  const handleSaleSelected = (saleId) => {
    setSaleId(saleId);
    dispatch(listAgent(1, 20, agentSearchKeyword, saleId));
  };

  const handleSubmitSearch = async () => {
    const searchData = {
      agentId,
      saleId,
      startDate,
      endDate,
    };
    dispatch(pullDataShipments(searchData));
  };

  const downloadExcel = (data) => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
    //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(workbook, `DataSheet.xlsx`);
  };

  return (
    <Box>
      <HStack spacing="4" my="4">
        <Box>
          {loading && (
            <Spinner label="Please wait..." speed="0.1s" thickness="5px" />
          )}
          {loadingAgentList && (
            <Spinner label="Please wait..." speed="0.1s" thickness="5px" />
          )}
          {loadingSaleList && (
            <Spinner label="Please wait..." speed="0.1s" thickness="5px" />
          )}
        </Box>
      </HStack>
      <Divider />

      <Box>
        <HStack my="3">
          <Stack>
            <Box>Sale</Box>
            <Input
              type="text"
              size="sm"
              w="300px"
              placeholder="Search Sale"
              value={saleSearchKeyword}
              onChange={(e) => setSaleSearchKeyword(e.target.value)}
              onKeyPress={handleSearchSale}
            />
            <Select
              w="300px"
              size="sm"
              placeholder="Sale List"
              onChange={(e) => handleSaleSelected(e.target.value)}
            >
              {sales &&
                sales.map((sa, idx) => (
                  <option key={idx} value={sa._id}>
                    {sa.saleCode} - {sa.name}
                  </option>
                ))}
            </Select>
          </Stack>

          <Stack>
            <Box>Agent</Box>
            <Input
              type="text"
              size="sm"
              w="300px"
              placeholder="Search Agent"
              value={agentSearchKeyword}
              onChange={(e) => setAgentSearchKeyword(e.target.value)}
              onKeyPress={handleSearchAgent}
            />
            <Select
              w="300px"
              size="sm"
              placeholder="Agent List"
              onChange={(e) => setAgentId(e.target.value)}
            >
              {agents &&
                agents.map((ag, idx) => (
                  <option key={idx} value={ag._id}>
                    {ag.name} - {ag.agentCode}
                  </option>
                ))}
            </Select>
          </Stack>

          <Stack>
            <Box>Start/End Date</Box>
            <Input
              type="date"
              size="sm"
              w="200px"
              placeholder="Start"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
            <Input
              type="date"
              size="sm"
              w="200px"
              placeholder="End"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </Stack>

          <Spacer />
          <Stack>
            <Button
              size={"sm"}
              disabled={
                agentId === "" &&
                saleId === "" &&
                startDate === "" &&
                endDate === ""
              }
              colorScheme={"green"}
              onClick={handleSubmitSearch}
            >
              GO
            </Button>
            <Button
              size={"sm"}
              disabled={shipments && shipments.length === 0}
              colorScheme={"purple"}
              onClick={() => downloadExcel(shipments)}
            >
              Excel
            </Button>
            <Button
              size={"sm"}
              colorScheme={"yellow"}
              onClick={() => window.location.reload(false)}
            >
              Clear
            </Button>
          </Stack>
        </HStack>
      </Box>

      <Divider />

      <Box my={"2"}>
        {error && <Error error={error} />}
        {errorAgentList && <Error error={errorAgentList} />}
        {errorSaleList && <Error error={errorSaleList} />}
      </Box>

      {!shipments || shipments.length === 0 ? (
        <Error error={"no data found"} />
      ) : (
        <Table size="xs" variant="striped" my="4">
          <Thead>
            <Tr>
              <Th>No</Th>
              <Th>AWB</Th>
              <Th>PreId</Th>
              <Th>Agent</Th>
              <Th>Ship Date</Th>
              <Th>Shipper</Th>
              <Th>Receiver</Th>
              <Th>Receiver Country</Th>
              <Th isNumeric>Weight</Th>
              <Th isNumeric>Amount</Th>
              <Th isNumeric>Payment</Th>
              <Th isNumeric>Shipper</Th>
              <Th isNumeric>Payer</Th>
            </Tr>
          </Thead>
          <Tbody>
            {shipments &&
              shipments.map((sm, index) => (
                <Tr key={index}>
                  <Td>{index + 1}</Td>
                  <Td>{sm.awb}</Td>
                  <Td>{sm.preCode}</Td>
                  <Td>{sm.agent}</Td>
                  <Td>{sm.createdAt.substring(0, 10)}</Td>
                  <Td>{sm.sender}</Td>
                  <Td>{sm.receiver}</Td>
                  <Td>{sm.receiverCountry}</Td>
                  <Td isNumeric>{sm.totalWeight}</Td>
                  <Td isNumeric>{sm.totalAmount && sm.totalAmount.toLocaleString()}</Td>
                  <Td isNumeric>{sm.isPaid}</Td>
                  <Td isNumeric>{sm.shipperAccount}</Td>
                  <Td isNumeric>{sm.payerAccount}</Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      )}
    </Box>
  );
};

export default PullDataScreen;
